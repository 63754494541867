import apiService from "./axiosInstance";

/*
 * GET - Get Array of Addresses from FTTH API
 */
export const fetchAddressService = () => {
  return apiService.get("/modems/available");
};

/*
 * POST - Create Customer, accepts customer data object
 */
export const createCustomerService = (data) => {
  return apiService.post("/customers", data);
};

/*
 * POST - EventAPI V2, capturing user event data and posting to eventAPIV2, on snowflake
 */
export const eventApiWrapperV2Service = (eventData) => {
  return apiService.post("/adopter", eventData);
};

/*
 * POST - Log to Event API
 */
export const eventApiLogger = (data) => {
  return apiService.post("/log/event", data);
};

/*
 * POST - Create Customer, accepts customer data object
 */
export const promoCodeService = (data) => {
  return apiService.post("/discounts", data);
};
