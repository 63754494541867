import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { eventApiLogger } from "../services/apiServices";
import { getBrowserAndOS } from "../config/getUserAgent";
import { generateGUID, getPageLoadtime, getQueryParam } from "../config/utils";

const getOrCreateGsid = () => {
  // Check cookie first
  const cookieGsid = document.cookie
    .split("; ")
    .find((row) => row.startsWith("fiber-sales-gsid="))
    ?.split("=")[1];

  if (cookieGsid) {
    return cookieGsid;
  }

  // Check query param if gsid exists and use this gsid
  const queryGsid = getQueryParam("gsid");
  if (queryGsid) {
    // Set cookie with 15 days expiry
    document.cookie = `fiber-sales-gsid=${queryGsid}; max-age=${
      60 * 60 * 24 * 15
    }; path=/`;
    return queryGsid;
  }

  // Generate new GSID, if no gsid is found,
  const newGsid = generateGUID();
  document.cookie = `fiber-sales-gsid=${newGsid}; max-age=${
    60 * 60 * 24 * 15
  }; path=/`;
  return newGsid;
};

const logEventApi = (data) => {
  const { browser, browserVersion, os } = getBrowserAndOS();

  const eventData = {
    ap_mac: getQueryParam("ap_mac"),
    gsid: getOrCreateGsid(),
    mac: getQueryParam("mac"),
    event: "onLoad",
    browser: browser,
    browser_version: browserVersion,
    event_level: "analytics",
    event_source: "frontend",
    event_type: "page_hit",
    location: window.location.href,
    on_load: getPageLoadtime(),
    os: os,
    page_title: document.title,
    product: "fiber-sales",
    referrer: document.referrer,
    screen_resolution: `${window.screen.width}x${window.screen.height}`,
    user_agent: navigator.userAgent,
    ...data,
  };

  eventApiLogger([eventData])
    .then(() => console.log("Fiber Sales event created"))
    .catch((error) => console.error("Error logging fiber sales event:", error));
};

const EventAPI = () => {
  const location = useLocation();
  const isFirstMount = useRef(true);
  const prevPathRef = useRef(location.pathname);
  const isPageRefresh = useRef(true);

  useEffect(() => {
    if (isFirstMount.current) {
      // Log initial page load
      logEventApi({
        product: "fiber-sales",
      });
      isFirstMount.current = false;
      return;
    }

    // Check if this is a page refresh or actual route change
    if (isPageRefresh.current) {
      isPageRefresh.current = false;
      return;
    }

    // Only log if the path has actually changed (SPA navigation)
    if (prevPathRef.current !== location.pathname) {
      logEventApi({
        event: "routeChange",
        event_type: "route_change",
        path: location.pathname,
        previous_path: prevPathRef.current,
        product: "fiber-sales",
      });
      prevPathRef.current = location.pathname;
    }
  }, [location]);

  if (location.pathname.includes("fiber-welcome")) {
    logEventApi({
      event: "conversion",
      event_type: "conversion",
      path: location.pathname,
      previous_path: prevPathRef.current,
      product: "fiber-sales",
    }); 
  }

  // Reset isPageRefresh on unmount/remount
  useEffect(() => {
    return () => {
      isPageRefresh.current = true;
    };
  }, []);

  useEffect(() => {
    // Clicks
    const handleClick = (event) => {
      const { target } = event;
      // exclude MUI classnames? 
      if (target.className) {
        logEventApi({
          event: "click",
          event_type: "click",
          path: location.pathname,
          element_clicked: target.className,
          product: "fiber-sales",
        }); 
      }
    };
    
    // Add the event listener to the window object
    window.addEventListener('click', handleClick);
    
    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  return null;
};

export default EventAPI;
