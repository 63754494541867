import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import { HashRouter as Router, Route, Routes } from "react-router-dom";

import "../src/assets/stylesheets/App.css";

import LandingPage from "./pages/LandingPage";
import AddressQualifications from "./pages/AddressQualifications";
import NotAvailable from "./pages/NotAvailable";
import StayTuned from "./pages/StayTuned";
import Registration from "./pages/Registration";
import SignupForm from "./pages/SignupForm";
import Completed from "./pages/Completed";
import PaymentForm from "./pages/PaymentForm";
import Welcome from "./pages/Welcome";

import { createTheme, ThemeProvider } from "@mui/material";
import boingoTheme from "./boingoTheme";
import StandardFaqs from "./pages/StandardFaqs";
import EventAPI from "../src/components/EventAPI";

function App() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [addressInfo, setAddressInfo] = useState("");

  //this finalized state will container, address, userinfo
  const [finalCustomerInfo, setFinalCustomerInfo] = useState("");

  const [modemData, setModemData] = useState({});

  // Create a props object for components
  const registrationProps = {
    register,
    handleSubmit,
    watch,
    errors,
    addressInfo,
    setAddressInfo,
    finalCustomerInfo,
    setFinalCustomerInfo,
    modemData,
    setModemData,
  };

  return (
    <>
      <div className="App">
        <ThemeProvider theme={boingoTheme}>
          <Router>
            <EventAPI />
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route
                path="/check-availability"
                element={<AddressQualifications {...registrationProps} />}
              />
              <Route
                path="/not-available"
                element={<NotAvailable {...registrationProps} />}
              />
              <Route path="/stay-tuned" element={<StayTuned />} />
              <Route
                path="/signup-form"
                element={<SignupForm {...registrationProps} />}
              />
              <Route
                path="/registration"
                element={<Registration {...registrationProps} />}
              />
              <Route path="/completed" element={<Completed />} />
              <Route
                path="/payment"
                element={<PaymentForm {...registrationProps} />}
              />
              <Route path="/fiber-welcome" element={<Welcome />} />
              <Route path="/faqs" element={<StandardFaqs/>}
              />
            </Routes>
          </Router>
        </ThemeProvider>
      </div>
    </>
  );
}

export default App;
